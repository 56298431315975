import { EventEmitter, Injectable } from '@angular/core';
import { AppConfigService } from '../config/app-config.service';

export class TransitionEvent {
  constructor(
    public name: string,
    public phase: string,
    public from: string,
    public to: string
  ) {}
}
@Injectable({
  providedIn: 'root',
})
export class TransitionService {
  public emitter$: EventEmitter<TransitionEvent>;

  START = 'TransitionService:START';
  DONE = 'TransitionService:DONE';

  constructor(private appConfig: AppConfigService) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: TransitionEvent): void {
    if (this.appConfig.DEBUG) {
      console.info(
        'TransitionService emitEvent %s',
        JSON.stringify(event, null, 4)
      );
    }
    this.emitter$.emit(event);
  }
}
