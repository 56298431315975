<div class="box error-box">
  <div class="logo">
    <img src="{{assetsUrl}}/assets/images/logo-soundclash.svg">
  </div>
  <div class="goal">
    <p class="red">Erreur {{errorCode}}</p>
    <p>Allô Houston, on a<br>un problème. Où est parti le jeu ?</p>
  </div>
  <div>
    {{msg}}
  </div>
</div>
