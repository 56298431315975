import { EventEmitter, Injectable } from '@angular/core';

export class BackgroundEvent {
  constructor(public name: string, public id: number) {}
}

@Injectable({
  providedIn: 'root',
})
export class BackgroundService {
  public emitter$: EventEmitter<BackgroundEvent>;

  public SHOW = 'BackgroundService:SHOW';
  public HIDE = 'BackgroundService:HIDE';

  constructor() {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: BackgroundEvent): void {
    // console.log('CursorService emitEvent %s', event.name);

    this.emitter$.emit(event);
  }

  show(id: number = 1): void {
    this.emitEvent(new BackgroundEvent(this.SHOW, id));
  }
  hide(): void {
    this.emitEvent(new BackgroundEvent(this.HIDE, -1));
  }
}
