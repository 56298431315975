<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form">
  <div class="box nickname">
    <div class="logo">
      <img src="{{assetsUrl}}/assets/images/icon-pseudo.svg">
    </div>
    <div class="goal">
      <span class="gold">Balance</span><br>ton pseudo !
    </div>
    <div class="input">
      <input id="inputfieldtxt" formControlName="nickname" type="text" minlength="MINSIZE" maxlength="MAXSIZE"
        submit-required="true" autocomplete="off" (input)="onChange($event)" spellcheck="false"
        [ngClass]="{ 'is-invalid': submitted && f.nickname.errors }" (keydown.backspace)="onChange($event)"
        placeholder="{{placeHolder}}" />
      <div *ngIf="f.nickname.errors" class="invalid invalid-feedback">
        <!-- <div *ngIf="f.nickname.errors?.required">nickname is required</div> -->
        <div *ngIf="f.nickname.errors?.minlength">nickname must be at least {{MINSIZE}} characters</div>
        <div *ngIf="f.nickname.errors?.maxlength">nickname must be at most {{MAXSIZE}} characters</div>
        <div *ngIf="f.nickname.errors?.invalidAsync">nickname is already taken</div>
      </div>
      <div *ngIf="!f.nickname.errors" class="invalid">
        &nbsp;
      </div>
    </div>
    <div>

      <button [disabled]="loading" class="submit-button focus">
        <span>{{ btn }}</span>
        <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto; background: none; display: block; shape-rendering: auto;" width="51px" height="51px"
            viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="loading">
            <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#fff"
              stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="square"
              transform="rotate(58.5425 50 50)">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1"
                values="0 50 50;360 50 50"></animateTransform>
            </circle>
          </svg>{{ btn }}</span>
      </button>
    </div>
  </div>

</form>
<div class="close" *ngIf="!submitted">
  <button class="close-btn" (click)="back()"><img src="{{assetsUrl}}/assets/images/rules/x.svg" class="X">
  </button>
</div>
