import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EndComponent } from './sections/end/end.component';
import { ErrorComponent } from './sections/error/error.component';
import { GameComponent } from './sections/game/game.component';
import { HomeComponent } from './sections/home/home.component';
import { NicknameComponent } from './sections/nickname/nickname.component';
import { PrehomeComponent } from './sections/prehome/prehome.component';
import { RankingComponent } from './sections/ranking/ranking.component';
import { RewardComponent } from './sections/reward/reward.component';
import { RulesComponent } from './sections/rules/rules.component';
import { SaveGameComponent } from './sections/save-game/save-game.component';
import { SelectGameComponent } from './sections/select-game/select-game.component';
import { SplashscreenComponent } from './sections/splashscreen/splashscreen.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('../app/modules/intro/intro.module').then((m) => m.IntroModule),
  // },
  // {
  //   path: 'game',
  //   loadChildren: () =>
  //     import('../app/modules/game/game.module').then((m) => m.GameModule),
  // },
  // {
  //   path: 'end',
  //   loadChildren: () =>
  //     import('../app/modules/end/end.module').then((m) => m.EndModule),
  // },
  {
    path: '',
    redirectTo: 'prehome',
    pathMatch: 'full',
  },
  {
    path: 'prehome',
    component: PrehomeComponent,
    data: { animation: 'PreHome' },
  },
  {
    path: 'splashscreen',
    component: SplashscreenComponent,
    data: { animation: 'SplashScreen' },
  },
  { path: 'home', component: HomeComponent, data: { animation: 'Home' } },
  {
    path: 'nickname',
    component: NicknameComponent,
    data: { animation: 'Nickname' },
  },
  { path: 'rules', component: RulesComponent, data: { animation: 'Rules' } },
  {
    path: 'nickname',
    component: NicknameComponent,
    data: { animation: 'Nickname' },
  },
  {
    path: 'select-game',
    component: SelectGameComponent,
    data: { animation: 'SelectGame' },
  },
  { path: 'game', component: GameComponent, data: { animation: 'Game' } },
  { path: 'end', component: EndComponent, data: { animation: 'End' } },
  {
    path: 'save-game',
    component: SaveGameComponent,
    data: { animation: 'SaveGame' },
  },
  {
    path: 'ranking',
    component: RankingComponent,
    data: { animation: 'Ranking' },
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: { animation: 'Error' },
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
