import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { timer, Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { CheckUser, ServerService } from 'src/app/services/http/server.service';

// https://medium.com/@rinciarijoc/angular-custom-async-validators-13a648d688d8
export class LoginValidator {
  static createValidator(serverService: ServerService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(serverService.DEBOUNCE).pipe(
        switchMap(() => {
          if (!control.value) {
            return of(null);
          }
          return serverService.check(control.value).pipe(
            map((result: CheckUser): ValidationErrors | null => {
              // console.warn('LoginValidator -> ' + result);
              return result.exists ? { invalidAsync: true } : null;
            })
          );
        })
      );
    };
  }
}
