import { Injectable } from '@angular/core';
import * as MobileDetect from 'mobile-detect';
import { UAParser } from 'ua-parser-js';
import { Element, Runner, SVG, Ease } from '@svgdotjs/svg.js';
import gsap from 'gsap';
import { RoughEase } from 'gsap/EasePack';
// import { SoundService } from '../sound/sound.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {
    // private soundService: SoundService
    this.initSvgEasing();
  }

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  parser = new UAParser();
  public svgEaseFn = {
    quadIn: Function,
    quadOut: Function,
    quadInOut: Function,
    cubicIn: Function,
    cubicOut: Function,
    cubicInOut: Function,
    quartIn: Function,
    quartOut: Function,
    quartInOut: Function,
    quintIn: Function,
    quintOut: Function,
    quintInOut: Function,
    sineIn: Function,
    sineOut: Function,
    sineInOut: Function,
    expoIn: Function,
    expoOut: Function,
    expoInOut: Function,
    circIn: Function,
    circOut: Function,
    circInOut: Function,
    backIn: Function,
    backOut: Function,
    backInOut: Function,
    swingFromTo: Function,
    swingFrom: Function,
    swingTo: Function,
    bounce: Function,
    bounceOut: Function,
    elastic: Function,
  };

  getRandom(min = 0, max = 1) {
    const randomNumber = Math.random() * (max - min) + min;
    return Math.floor(randomNumber);
  }

  getViewportSize() {
    const obj = {
      width: 0,
      height: 0,
    };
    obj.width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    obj.height = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    return obj;
  }

  getUIScale(): number {
    const deviceSize = this.getViewportSize();

    return Math.min(deviceSize.width / 375, deviceSize.height / 667) * 0.7;
  }

  md(): MobileDetect {
    return this.mobileDetect;
  }

  pad(str: string | number | any[], max: number): string {
    str = str.toString();
    return str.length < max ? this.pad('0' + str, max) : str;
  }
  blink(el: any, delay = 0): any {
    // tslint:disable-next-line: no-shadowed-variable
    const promise = new Promise((resolve) => {
      setTimeout(() => {
        // this.soundService.blink();

        const tween = gsap.to(el, {
          // delay: delay / 1000,
          duration: 0.45,
          opacity: 1,
          ease: RoughEase.ease.config({
            points: 10,
            strength: 3,
            randomize: true,
            clamp: true,
          }),
          onComplete: () => {
            resolve(tween);
          },
        });
      }, delay);
    });
    return promise;
  }

  blinkDOM(el: any, del = 0): any {
    const promise = new Promise((resolve) => {
      gsap.to(el, { duration: 0, opacity: 1 });
      const tl = gsap.timeline({
        delay: del,
        onComplete: () => {
          resolve(true);
        },
      });
      tl.to(el, { opacity: 0, duration: 0.1 });
      tl.to(el, { opacity: 1, duration: 0.18 });
      tl.to(el, { opacity: 0, delay: 0.05, duration: 0.05 });
      tl.to(el, { opacity: 1, duration: 0.05 });
      tl.to(el, { opacity: 0, duration: 0 });
      tl.to(el, {
        opacity: 1,
        delay: 0.05,
        duration: 0.15,
      });
      tl.play();
    });

    return promise;
  }

  initSvgEasing(): void {
    gsap.registerPlugin(RoughEase);

    const easing = {
      quadIn: (pos: number) => {
        return Math.pow(pos, 2);
      },

      quadOut: (pos: number) => {
        return -(Math.pow(pos - 1, 2) - 1);
      },

      quadInOut: (pos: number) => {
        // tslint:disable-next-line: no-conditional-assignment
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 2);
        }
        return -0.5 * ((pos -= 2) * pos - 2);
      },

      cubicIn: (pos: number) => {
        return Math.pow(pos, 3);
      },

      cubicOut: (pos: number) => {
        return Math.pow(pos - 1, 3) + 1;
      },

      cubicInOut: (pos: number) => {
        // tslint:disable-next-line: no-conditional-assignment
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 3);
        }
        return 0.5 * (Math.pow(pos - 2, 3) + 2);
      },

      quartIn: (pos: number) => {
        return Math.pow(pos, 4);
      },

      quartOut: (pos: number) => {
        return -(Math.pow(pos - 1, 4) - 1);
      },

      quartInOut: (pos: number) => {
        // tslint:disable-next-line: no-conditional-assignment
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 4);
        }
        return -0.5 * ((pos -= 2) * Math.pow(pos, 3) - 2);
      },

      quintIn: (pos: number) => {
        return Math.pow(pos, 5);
      },

      quintOut: (pos: number) => {
        return Math.pow(pos - 1, 5) + 1;
      },

      quintInOut: (pos: number) => {
        // tslint:disable-next-line: no-conditional-assignment
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 5);
        }
        return 0.5 * (Math.pow(pos - 2, 5) + 2);
      },

      sineIn: (pos: number) => {
        return -Math.cos(pos * (Math.PI / 2)) + 1;
      },

      sineOut: (pos: number) => {
        return Math.sin(pos * (Math.PI / 2));
      },

      sineInOut: (pos: number) => {
        return -0.5 * (Math.cos(Math.PI * pos) - 1);
      },

      expoIn: (pos: number) => {
        return pos === 0 ? 0 : Math.pow(2, 10 * (pos - 1));
      },

      expoOut: (pos: number) => {
        return pos === 1 ? 1 : -Math.pow(2, -10 * pos) + 1;
      },

      expoInOut: (pos: number) => {
        if (pos === 0) {
          return 0;
        }
        if (pos === 1) {
          return 1;
        }
        // tslint:disable-next-line: no-conditional-assignment
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(2, 10 * (pos - 1));
        }
        return 0.5 * (-Math.pow(2, -10 * --pos) + 2);
      },

      circIn: (pos: number) => {
        return -(Math.sqrt(1 - pos * pos) - 1);
      },

      circOut: (pos: number) => {
        return Math.sqrt(1 - Math.pow(pos - 1, 2));
      },

      circInOut: (pos: number) => {
        // tslint:disable-next-line: no-conditional-assignment
        if ((pos /= 0.5) < 1) {
          return -0.5 * (Math.sqrt(1 - pos * pos) - 1);
        }
        return 0.5 * (Math.sqrt(1 - (pos -= 2) * pos) + 1);
      },

      backIn: (pos: number) => {
        const s = 1.70158;
        return pos * pos * ((s + 1) * pos - s);
      },

      backOut: (pos: number) => {
        pos = pos - 1;
        const s = 1.70158;
        return pos * pos * ((s + 1) * pos + s) + 1;
      },

      backInOut: (pos: number) => {
        let s = 1.70158;
        // tslint:disable-next-line: no-conditional-assignment
        if ((pos /= 0.5) < 1) {
          return 0.5 * (pos * pos * (((s *= 1.525) + 1) * pos - s));
        }
        return 0.5 * ((pos -= 2) * pos * (((s *= 1.525) + 1) * pos + s) + 2);
      },

      swingFromTo: (pos: number) => {
        let s = 1.70158;
        // tslint:disable-next-line: no-conditional-assignment
        return (pos /= 0.5) < 1
          ? 0.5 * (pos * pos * (((s *= 1.525) + 1) * pos - s))
          : 0.5 * ((pos -= 2) * pos * (((s *= 1.525) + 1) * pos + s) + 2);
      },

      swingFrom: (pos: number) => {
        const s = 1.70158;
        return pos * pos * ((s + 1) * pos - s);
      },

      swingTo: (pos: number) => {
        const s = 1.70158;
        return (pos -= 1) * pos * ((s + 1) * pos + s) + 1;
      },

      bounce: (pos: number) => {
        const s = 7.5625;
        const p = 2.75;
        let l;

        if (pos < 1 / p) {
          l = s * pos * pos;
        } else {
          if (pos < 2 / p) {
            pos -= 1.5 / p;
            l = s * pos * pos + 0.75;
          } else {
            if (pos < 2.5 / p) {
              pos -= 2.25 / p;
              l = s * pos * pos + 0.9375;
            } else {
              pos -= 2.625 / p;
              l = s * pos * pos + 0.984375;
            }
          }
        }
        return l;
      },

      bounceOut: (pos: number) => {
        if (pos < 1 / 2.75) {
          return 7.5625 * pos * pos;
        } else if (pos < 2 / 2.75) {
          return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
        } else if (pos < 2.5 / 2.75) {
          return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
        } else {
          return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
        }
      },

      // tslint:disable-next-line: only-arrow-functions
      elastic: (pos: any) => {
        if (pos === !!pos) {
          return pos;
        }
        return (
          Math.pow(2, -10 * pos) *
            Math.sin(((pos - 0.075) * (2 * Math.PI)) / 0.3) +
          1
        );
      },
    };

    // tslint:disable-next-line: forin
    for (const key in easing) {
      // SVG.easing[key] = easing[key];
      // this.svgEaseFn[key] = easing[key];
    }
  }

  toHHMMSS(value: number) {
    const total = Math.ceil(value);
    const hours = Math.floor(total / 3600);
    const minutes = Math.floor((total - hours * 3600) / 60);
    const seconds = total - hours * 3600 - minutes * 60;

    return (
      this.pad(hours, 2) +
      ' : ' +
      this.pad(minutes, 2) +
      ' : ' +
      this.pad(seconds, 2)
    );
  }
}
