<div id="connectContainer" class="flex-column justify-content-center align-items-center h-100">
  <div class="d-flex flex-column justify-content-center align-items-center h-100">
    <alert></alert>

    <mat-tab-group mat-align-tabs="center" animationDuration="300ms" dynamicHeight disableRipple>
      <mat-tab label="Créer un compte">
        <register></register>
      </mat-tab>
      <mat-tab label=" Déjà un compte">
        <login></login>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
