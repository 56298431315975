import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routeAnimations } from 'src/app/animations/route.animations';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { PlayerService } from 'src/app/services/user/player.service';
import gsap from 'gsap';
import * as $ from 'jquery';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BackgroundService } from 'src/app/services/view/background.service';
import {
  TransitionService,
  TransitionEvent,
} from 'src/app/services/view/transition.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services/utilities/utils.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [routeAnimations],
})
export class HomeComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private appConfig: AppConfigService,
    private playerService: PlayerService,
    private $gaService: GoogleAnalyticsService,
    private transitionService: TransitionService,
    private utils: UtilsService,
    private backgroundService: BackgroundService
  ) {}
  btn1 = 'Règles du jeu';
  btn2 = 'Classement';
  btn3 = 'Nouvelle partie';
  assetsUrl = this.appConfig.assetsUrl;
  subscription: Subscription;

  ngOnInit(): void {
    this.$gaService.pageView('/home', 'Home Menu');
    this.subscription = this.transitionService.emitter$.subscribe(
      (event: TransitionEvent) => this.eventDispatch(event)
    );
  }

  ngAfterViewInit(): void {
    // this._onCanvasResize();
  }

  eventDispatch(event: TransitionEvent): void {
    switch (event.name) {
      case this.transitionService.START:
        if (event.to === 'Home') {
          // setTimeout(() => {
          //   this.backgroundService.show(1);
          // }, 1000);
        }
        break;
      case this.transitionService.DONE:
        if (event.to === 'Home') {
        }
        break;

      default:
        break;
    }
  }

  start(): void {
    // if (!(screenfull as Screenfull).isFullscreen) {
    //   (screenfull as Screenfull).toggle();
    // }
    this.$gaService.event(
      'Nouvelle partie (Home)',
      'Interactions',
      'Nouvelle partie (Home)'
    );
    if (this.playerService.isOnline) {
      // setTimeout(() => {
      this.ngZone.run(() => {
        this.router.navigate(['/select-game'], {
          skipLocationChange: true,
        });
      });
      // }, 1);
    } else {
      // setTimeout(() => {
      // this.router.navigate(['/nickname'], {
      this.ngZone.run(() => {
        this.router.navigate(['/select-game'], {
          skipLocationChange: true,
        });
      });
      // }, 1);
    }
    this.backgroundService.hide();
  }

  ranking(): void {
    this.$gaService.event(
      'Classement (home)',
      'Interactions',
      'Classement (home)'
    );
    // setTimeout(() => {
    this.ngZone.run(() => {
      this.router.navigate(['/ranking'], {
        skipLocationChange: true,
      });
    });
    // }, 1);
  }

  rules(): void {
    this.$gaService.event(
      'Règles du jeu (home)',
      'Interactions',
      'Règles du jeu (home)'
    );
    // setTimeout(() => {
    this.ngZone.run(() => {
      this.router.navigate(['/rules'], {
        skipLocationChange: true,
      });
    });
    // }, 1);
  }

  _onCanvasResize() {
    if (this.appConfig.DEBUG) {
      console.log(
        'HomeComponent:_onCanvasResize scale : ' + this.utils.getUIScale()
      );
    }

    if (this.utils.getUIScale() > 1) {
      $('#home').css('transform', 'scale(' + this.utils.getUIScale() + ')');
    } else {
      $('#home').css('transform', 'unset');
    }
  }
}
