<div class="box splashscreen">
  <div class="logo">
    <img src="{{assetsUrl}}/assets/images/logo-swipe.svg">
  </div>
  <div class="vs d-flex flex-row justify-content-center align-items-center">
    <div class="flex-fill leto"><img src="{{assetsUrl}}/assets/images/splashscreen/leto.png"></div>
    <div class="flex-fill guy"><img src="{{assetsUrl}}/assets/images/splashscreen/guy.png"></div>
  </div>
  <div class="d-flex flex-row justify-content-evenly align-items-center w-100">
    <div class="vs1">
      <img src="{{assetsUrl}}/assets/images/splashscreen/leto.svg">
    </div>
    <div class="vs-icon">
      <img src="{{assetsUrl}}/assets/images/splashscreen/vs.svg">
    </div>
    <div class="vs2">
      <img class="logo-guy" src="{{assetsUrl}}/assets/images/splashscreen/guy-2-bezbar@3x.png">
    </div>
  </div>
  <div class="touch2start">
    Touche pour commencer
  </div>
</div>