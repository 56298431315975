<div class="box ranking">
  <div class="">
    <img src="{{assetsUrl}}/assets/images/titre-classement@3x.png" class="img-fluid ranking-logo">
  </div>
  <!-- <div class="goal">
    <span class="gold">Classement</span>
  </div> -->
  <div class="colonne">
    <div class="ligne top" *ngIf="showCurrentPlayer">
      <div class="name">
        <div class="index">{{ currentPlayer.position }}.</div>
        <div>{{ currentPlayer.name }}</div>
      </div>
      <div class="score">
        <div class="">{{ currentPlayer.scoreStr }}</div>
        <div>
          <img src="{{assetsUrl}}/assets/images/yellow-stars.svg" class="Stars">
        </div>
      </div>
    </div>
    <div class="ligne" *ngFor="let player of ranking; let i = index">
      <div class="name">
        <div class="index">{{ i + 1 }}.</div>
        <div>{{ player.name }}</div>
      </div>
      <div class="score">
        <div class="">{{ player.scoreStr }}</div>
        <div>
          <img src="{{assetsUrl}}/assets/images/stars.svg" class="Stars">
        </div>
      </div>
    </div>
  </div>


</div>
<div class="close">
  <button class="close-btn" (click)="close()"><img src="{{assetsUrl}}/assets/images/rules/x.svg" class="X">
  </button>
</div>