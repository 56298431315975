import { Injectable, EventEmitter } from '@angular/core';
import { ServerService } from '../http/server.service';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { ToastrService } from 'ngx-toastr';
import { PlayerService } from '../user/player.service';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Combo, ComboBuffer } from 'src/app/models/combo';
import { Card } from 'src/app/models/card';

export class ComboEvent {
  constructor(
    public name: string,
    public comboList: Array<Combo>,
    public resolve?: any
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class ComboService {
  public emitter$: EventEmitter<ComboEvent>;
  combosBuffer: Array<ComboBuffer>;
  combosData: Array<Combo>;
  comboCount: any;
  previousComboID: string;
  matched: Array<Combo>;
  delayed: Array<Combo>;
  currentMultiplier: number;
  isActive: boolean;
  totalMatched: number;
  quizSubs: Subscription;

  public SHOW = 'ComboService:SHOW';
  public HIDE = 'ComboService:HIDE';
  public HIDDEN = 'ComboService:HIDDEN';
  public STARTPOPCORNTIME = 'ComboService:STARTPOPCORNTIME';
  public ENDPOPCORNTIME = 'ComboService:ENDPOPCORNTIME';
  public KAZUHISA = 'ComboService:KAZUHISA';
  public CHEATCODE = 'ComboService:CHEATCODE';

  constructor(
    private serverService: ServerService,
    private appConfig: AppConfigService,
    private playerService: PlayerService,
    private $gaService: GoogleAnalyticsService,
    // private rbTracking: RedbullTrackingService,
    private toastr: ToastrService
  ) {
    this.emitter$ = new EventEmitter();
  }

  public emitEvent(event: ComboEvent): void {
    if (this.appConfig.DEBUG) {
      console.log('ComboService emitEvent %s', event.name);
    }
    this.emitter$.emit(event);
  }

  init(): void {
    this.combosBuffer = [];
    this.comboCount = {};
    this.previousComboID = '';
    this.matched = [];
    this.delayed = [];
    this.currentMultiplier = 1;
    this.isActive = false;
    this.totalMatched = 0;

    this.serverService.getCombos().subscribe(
      (data: Array<Combo>) => {
        if (this.appConfig.DEBUG) {
          // console.log('ComboService:init ' + JSON.stringify(data, null, 4));
        }
        this.combosData = data;
      },
      (err) => {
        if (this.appConfig.DEBUG) {
          console.log('ComboService:init 404 !' + err);
        }
        this.$gaService.event(
          'Fatal Error',
          'Events',
          'Combos infos not loaded'
        );
        this.toastr
          .error(
            '[66] Error Red Bull Swipe Game : ' +
              err.status +
              ' (' +
              err.statusText +
              '). Relance le jeu stp !',
            'Erreur Fatale',
            {
              progressBar: true,
            }
          )
          .onHidden.subscribe(() => {
            // location.reload(true);
          });
        this.combosData = [];
      }
    );
  }

  // ajout du dernier comboID dans l'historique
  addCombo(card: Card): void {
    const combo = new ComboBuffer();
    combo.comboID = card.comboID;
    combo.flag = 0;
    combo.type = card.level;
    combo.name = card.imageID;
    if (this.isActive && combo.comboID.indexOf('bonus') >= 0) {
      if (this.appConfig.DEBUG) {
        console.log(
          'ComboService:addCombo PopCorn Time IS ACTIVE => ignoring entry'
        );
      }
      return;
    }

    this.combosBuffer.push(combo);
    if (this.combosBuffer.length > this.appConfig.COMBO_MAX_HISTORY) {
      this.combosBuffer.shift();
    }

    if (this.appConfig.DEBUG) {
      console.log(
        '%cComboService:addCombo' + JSON.stringify(this.combosBuffer),
        'color:black; background-color:yellow;padding:10px;padding-top: 10px;padding-bottom:10px;'
      );
    }
  }

  checkCombos(): any {
    const promise = new Promise((resolve) => {
      this.comboCount = {};

      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.combosData.length; index++) {
        const combo = this.combosData[index];
        if (combo.active === 0) {
          continue;
        }
        if (this.appConfig.DEBUG) {
          console.log(
            '<<< ComboService:checkCombos %s (%s) >>>>',
            combo.comboName,
            combo.comboID
          );
        }

        if (combo.consecutive === 1) {
          this.checkConsecutiveCombo(combo);
        } else {
          this.checkNonConsecutiveCombo(combo);
        }
      }

      if (this.matched.length > 0) {
        this.showCombo(resolve);
        // resolve(true);
      } else {
        resolve(true); // retour à la boucle principale du jeu
      }
    });

    // console.log('***COUNT: ' + JSON.stringify(this.comboCount, null, 4));

    return promise;
  }

  checkConsecutiveCombo(combo: Combo): boolean {
    if (this.appConfig.DEBUG) {
      console.log('ComboService:checkConsecutiveCombo');
    }
    this.previousComboID = '';

    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.combosBuffer.length; index++) {
      const buffer = this.combosBuffer[index];

      // test si le comboId match
      if (buffer.comboID === combo.comboID) {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkConsecutiveCombo ID found ! ' + combo.comboID
          );
        }

        // check si les flags matches
        if (this.checkFlag(combo, buffer) && this.checkType(combo, buffer)) {
          if (this.appConfig.DEBUG) {
            console.log('ComboService:checkConsecutiveCombo flags match !');
            console.log(
              'ComboService:checkConsecutiveCombo previousComboID: ' +
                this.previousComboID
            );
          }

          // test si on match le precedent
          if (buffer.comboID === this.previousComboID) {
            // si oui on augmente le compte et on stocke la valuer de l'id

            if (
              (this.isActive && combo.comboType === 2) ||
              this.isActive === false
            ) {
              if (this.comboCount[combo.comboID] !== undefined) {
                this.comboCount[combo.comboID] += 1;
              } else {
                this.comboCount[combo.comboID] = 1;
              }
            }
          } else {
            // this.comboCount[this.previousComboID] = 0;
            this.comboCount[buffer.comboID] = 1;
          }
        }
      }
      this.previousComboID = buffer.comboID;
    }
    if (this.appConfig.DEBUG) {
      console.log(
        'ComboService:checkConsecutiveCombo count ' +
          JSON.stringify(this.comboCount, null, 4)
      );
    }

    if (this.comboCount[combo.comboID] === combo.total) {
      // MATCH !
      // console.log(
      //   '!!!! ComboService:checkConsecutiveCombo MATCH ' + combo.comboName
      // );

      this.matched.push(combo);
      if (combo.comboType === 2) {
        this.totalMatched++;
      }
      if (combo.comboType === 1) {
        this.isActive = true;
        this.currentMultiplier = combo.multiplier;
      }
      if (this.appConfig.DEBUG) {
        console.log(
          '%cComboService:checkConsecutiveCombo MATCH ' + combo.comboName,
          'color:white; background-color:green; font-size:14px; padding:100px;padding-top: 10px;padding-bottom:10px;'
        );
      }

      this.$gaService.event(
        'Combo Consecutive Unlocked',
        'Events',
        combo.comboName
      );

      let multiplier = combo.multiplier;
      if (combo.comboType === 2) {
        multiplier = this.currentMultiplier;
      }

      this.updateScore(combo);

      if (combo.comboType === 2) {
        combo.active = 0;
      } else {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkConsecutiveCombo reset count of ' + combo.comboID
          );
        }
        this.comboCount[combo.comboID] = 0;
        this.filterOut(combo.comboID);
      }

      return true;
    } else {
      return false;
    }
  }

  checkNonConsecutiveCombo(combo: Combo): boolean {
    if (this.appConfig.DEBUG) {
      console.log('ComboService:checkNonConsecutiveCombo');
    }

    // on parcours le buffer à la recherche du comboID
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.combosBuffer.length; index++) {
      const buffer = this.combosBuffer[index];
      // test si le comboId match
      if (buffer.comboID === combo.comboID) {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkNonConsecutiveCombo ID match ! ' + combo.comboID
          );
        }
        // check si les flags matches
        if (this.checkFlag(combo, buffer) && this.checkType(combo, buffer)) {
          // si oui on augente le compte
          if (this.appConfig.DEBUG) {
            console.log('ComboService:checkNonConsecutiveCombo flags match !');
          }
          if (
            (this.isActive && combo.comboType === 2) ||
            this.isActive === false
          ) {
            if (this.comboCount[combo.comboID] !== undefined) {
              this.comboCount[combo.comboID] += 1;
            } else {
              this.comboCount[combo.comboID] = 1;
            }
          }
        }
      }
    }

    if (this.appConfig.DEBUG) {
      console.log(
        'ComboService:checkNonConsecutiveCombo count ' +
          JSON.stringify(this.comboCount, null, 4)
      );
    }
    // if (this.comboCount[combo.comboName + '_' + combo.type] === combo.total) {
    if (this.comboCount[combo.comboID] === combo.total) {
      // MATCH !
      // console.log(
      //   '!!!! ComboService:checkNonConsecutiveCombo MATCH ' + combo.comboName
      // );
      this.matched.push(combo);
      if (combo.comboType === 2) {
        this.totalMatched++;
      }
      if (combo.comboType === 1) {
        this.isActive = true;
        this.currentMultiplier = combo.multiplier;
      }
      if (this.appConfig.DEBUG) {
        console.log(
          '%cComboService:checkNonConsecutiveCombo MATCH ' + combo.comboName,
          'color:white; background-color:green; font-size:14px; padding:100px;padding-top: 10px;padding-bottom:10px;'
        );
      }

      this.$gaService.event(
        'Combo NonConsecutive Unlocked',
        'Events',
        combo.comboName
      );

      this.updateScore(combo);

      if (combo.comboType === 2) {
        combo.active = 0;
      } else {
        if (this.appConfig.DEBUG) {
          console.log(
            'ComboService:checkNonConsecutiveCombo reset count of ' +
              combo.comboID
          );
        }
        this.comboCount[combo.comboID] = 0;
        this.filterOut(combo.comboID);
      }

      return true;
    } else {
      return false;
    }
  }

  updateScore(combo: Combo): void {}

  checkFlag(combo: Combo, buffer: ComboBuffer): boolean {
    if (combo.flag === -1) {
      return true;
    }
    if (combo.flag === buffer.flag) {
      return true;
    }
    return false;
  }

  checkType(combo: Combo, buffer: ComboBuffer): boolean {
    if (combo.type === -1) {
      return true;
    }
    if (combo.type === buffer.type) {
      return true;
    }
    return false;
  }

  showCombo(gameResolve: any): void {
    if (this.totalMatched > 6) {
      gameResolve(true);
      return;
    }
    if (this.appConfig.DEBUG) {
      console.log(
        '%cComboService:showCombo TOTAL MATCHED : ' + this.totalMatched,
        'color:white; background-color:green; font-size:14px; padding:100px;padding-top: 10px;padding-bottom:10px;'
      );
    }
    this.emitEvent(new ComboEvent(this.SHOW, this.matched, gameResolve));
    this.matched = [];
    if (this.appConfig.DEBUG) {
      console.log(
        'ComboService:showCombo buffer ' +
          JSON.stringify(this.combosBuffer, null, 4)
      );
    }
    this.isActive = false;
    // this.combosBuffer = [];
  }

  getDescription(comboId: string): string {
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.combosData.length; index++) {
      const combo = this.combosData[index];
      if (combo.comboID === comboId) {
        if (this.appConfig.DEBUG) {
          console.log('ComboService:getDescription ' + combo.comboName);
        }
        return combo.description;
      }
    }
    return '';
  }

  godMode(): void {
    if (this.appConfig.DEBUG) {
      console.log('ComboService:godMode');
    }

    this.$gaService.event('God Mode Unlocked', 'Events', 'Invincible');
    let godModeCombo = new Combo();

    for (let index = 0; index < this.combosData.length; index++) {
      const combo = this.combosData[index];
      if (combo.comboID === 'godmode') {
        godModeCombo = combo;
      }
    }

    this.emitEvent(new ComboEvent(this.KAZUHISA, [godModeCombo]));
  }

  cheatCode(): void {
    this.$gaService.event('Cheat Code Unlocked', 'Events', 'Cheat Code');
    // let cheatCodeCombo = new Combo();

    for (let index = 0; index < this.combosData.length; index++) {
      const combo = this.combosData[index];
      if (combo.comboID === 'cheatcode') {
        const cheatCodeCombo = combo;

        this.updateScore(cheatCodeCombo);

        this.emitEvent(new ComboEvent(this.CHEATCODE, [cheatCodeCombo]));
      }
    }
  }

  filterOut(comboId: string): void {
    if (this.appConfig.DEBUG) {
      console.log('ComboService:filterOut comboId ' + comboId);
    }
    // const filtered = this.combosBuffer.filter((value, index, arr) => {
    //   return value.comboID !== comboId;
    // });

    for (const i in this.combosBuffer) {
      if (this.combosBuffer[i].comboID === comboId) {
        this.combosBuffer[i].comboID = 'done';
      }
    }
    if (this.appConfig.DEBUG) {
      console.log(
        'ComboService:filterOut  combosBuffer ' +
          JSON.stringify(this.combosBuffer, null, 4)
      );
    }
  }

  resetBuffer(): void {
    this.combosBuffer = [];
  }

  public get multiplier(): number {
    return this.currentMultiplier;
  }

  public set multiplier(v: number) {
    this.currentMultiplier = v;
  }

  public get total(): number {
    return this.totalMatched;
  }
  public set total(num: number) {
    this.totalMatched = num;
  }

  public get active(): boolean {
    return this.isActive;
  }

  public set active(v: boolean) {
    this.isActive = v;
  }
}
