import { EventEmitter, Injectable } from '@angular/core';
import { AppConfigService } from '../config/app-config.service';
import { Card } from 'src/app/models/card';

export class SwipeEvent {
  constructor(
    public name: string,
    public choice: boolean,
    public card: Card,
    public always?: boolean
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class SwipeService {
  public emitter$: EventEmitter<SwipeEvent>;

  constructor(private appConfig: AppConfigService) {
    this.emitter$ = new EventEmitter();
  }

  public SHOW = 'SwipeService:SHOW';
  public SWIPED = 'SwipeService:SWIPED';
  public NOMORECARDS = 'SwipeService:NOMORECARDS';

  public emitEvent(event: SwipeEvent): void {
    if (this.appConfig.DEBUG) {
      console.log(
        'SwipeService emitEvent %s / %s / %s / %s',
        event.name,
        event.choice,
        event.card.imageID,
        event.always
      );
    }
    this.emitter$.emit(event);
  }
  show(): void {
    this.emitEvent(new SwipeEvent(this.SHOW, false, new Card(), false));
  }
}
