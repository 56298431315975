import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from './services/config/app-config.service';
import { UtilsService } from './services/utilities/utils.service';
import { routeAnimations } from 'src/app/animations/route.animations';
import gsap from 'gsap';
import * as $ from 'jquery';
import {
  TransitionEvent,
  TransitionService,
} from './services/view/transition.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private utils: UtilsService,
    private transitionService: TransitionService,
    private appConfig: AppConfigService,
    private $gaService: GoogleAnalyticsService
  ) {}
  isMobile = false;

  ngOnInit() {
    const OS = this.utils.parser.getOS().name;
    const browser = this.utils.parser.getBrowser().name;
    if (this.utils.md().mobile() !== null) {
      this.isMobile = true;
    }
    let platform = this.utils.md().mobile();
    if (platform === null) {
      platform = 'Desktop';
    }

    window.parent.postMessage('hide-footer', '*');
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;
    // if (this.utils.md().mobile()) {
    //   innerWidth = window.screen.width;
    //   innerHeight = window.screen.height;
    // }
    if (innerWidth > innerHeight) {
      $('.main').css('height', innerWidth + 'px');
    } else {
      $('.main').css('height', innerHeight + 'px');
      $('.background').css('height', innerHeight + 'px');
    }

    // $('.footer').css('display', 'none');

    console.log(
      '%c Swipe 90s ' + this.appConfig.APP_VERSION,
      'color:black; background-color:#FFCB06; font-size:14px; padding:50px;padding-top: 10px;padding-bottom:10px;'
    );
    console.log(
      '%c Created by PopCorn 66 x ESD  \nhttps://www.popcorn66.fr',
      'color:white; background-color:#E41B4D; font-size:14px; padding:50px;padding-top: 10px;padding-bottom:10px;'
    );
    console.log(
      '%c[' + platform + ' / ' + OS + ' / ' + browser + ']',
      'color:white; background-color:#19355D; font-size:12px; padding:50px;padding-top: 10px;padding-bottom:10px;'
    );
    if (this.appConfig.DEBUG) {
      console.log(
        'window resolution ' +
          window.innerWidth +
          'px X ' +
          window.innerHeight +
          'px'
      );
    }

    this.$gaService.event(
      'App Version',
      'Events',
      'version ' + this.appConfig.APP_VERSION
    );

    if (this.isMobile) {
      this.router.navigate(['/prehome'], {
        skipLocationChange: true,
      });
    } else {
      // Mobile only
      this.$gaService.event('Error', 'Events', '505');

      this.router.navigate(['/error', { errorCode: 505 }], {
        skipLocationChange: true,
      });
    }
  }

  onAnimationEvent(event: any) {
    // console.warn(`Animation Trigger: ${event.triggerName}`);
    // phaseName is start or done
    if (event.phaseName === 'start') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      $('.main').removeClass('scrollable');

      this.transitionService.emitEvent(
        new TransitionEvent(
          this.transitionService.START,
          event.phaseName,
          event.fromState,
          event.toState
        )
      );
    }
    if (event.phaseName === 'done') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.transitionService.emitEvent(
        new TransitionEvent(
          this.transitionService.DONE,
          event.phaseName,
          event.fromState,
          event.toState
        )
      );
    }
  }
}
