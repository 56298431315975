import { Position } from './position';

export class Particle {
  idx: number;
  GRAVITY = 0.06;
  alpha: number;
  easing = 0.03;
  fade = 0.01;
  container: any;
  pos: Position;
  vel: Position;
  lastPos: Position;
  target: Position;
  usePhysics: boolean;
  bitmap: any;
  rotation = 0;
  assetsUrl = '';

  constructor(
    canvas: any,
    pos: Position,
    target: Position,
    vel: Position,
    alpha: number,
    idx: number,
    usePhysics: boolean,
    assetsUrl: string
  ) {
    this.idx = idx;
    this.container = canvas;
    this.alpha = alpha;
    this.usePhysics = usePhysics || false;
    this.pos = {
      x: pos.x || 0,
      y: pos.y || 0,
    };

    this.vel = {
      x: vel.x || 0,
      y: vel.y || 0,
    };

    this.lastPos = {
      x: this.pos.x,
      y: this.pos.y,
    };

    this.target = {
      x: 0,
      y: target.y || 0,
    };
    this.loadImage();
  }

  loadImage(): void {
    let img = new Image();
    img.onload = () => {
      var scale = this.getRandom(70, 100) / 500;
      var rotation = Math.random() * 360;

      this.bitmap = new window.createjs.Bitmap(img);

      var width = this.bitmap.image.width;
      var height = this.bitmap.image.height;

      this.bitmap.x = -width / 2;
      this.bitmap.y = -height / 2;
      this.bitmap.scaleX = this.bitmap.scaleY = scale;
      this.rotation = rotation;

      this.bitmap.alpha = this.alpha;
      this.container.addChild(this.bitmap);
    };
    img.src =
      'https://addons-redbullswipegame.redbull.com/assets/images/star.png';
  }

  getRandom(min: number, max: number) {
    let randomNumber = Math.random() * (max - min) + min;
    return Math.floor(randomNumber);
  }

  update(): boolean {
    this.lastPos.x = this.pos.x;
    this.lastPos.y = this.pos.y;

    if (this.usePhysics) {
      this.vel.y += this.GRAVITY;
      this.pos.y += this.vel.y;

      // since this value will drop below
      // zero we'll occasionally see flicker,
      // ... just like in real life! Woo! xD
      this.alpha -= this.fade;
    } else {
      var distance = this.target.y - this.pos.y;

      // ease the position
      this.pos.y += distance * (0.03 + this.easing);

      // cap to 1
      this.alpha = Math.min(distance * distance * 0.00005, 1);
    }

    this.pos.x += this.vel.x;
    if (this.alpha < 0.1) {
      this.clean();
      this.alpha = 0;
    }
    return this.alpha < 0.1;
  }

  render(): void {
    let x = Math.round(this.pos.x),
      y = Math.round(this.pos.y),
      xVel = (x - this.lastPos.x) * -5,
      yVel = (y - this.lastPos.y) * -5;
    if (this.bitmap) {
      // console.log("render particle "+this.bitmap);
      this.bitmap.x = this.pos.x + xVel;
      this.bitmap.y = this.pos.y + yVel;
      this.bitmap.alpha = this.alpha;
    }
  }

  clean(): void {
    this.container.removeChild(this.bitmap);
    this.bitmap = null;
  }
}
