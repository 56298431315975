<button type="buttonType" class="wide-button" (click)="onClickButton($event)" (mouseover)="onRollOverButton($event)"
  (mouseout)="onRollOutButton($event)" [disabled]="isDisabled" [ngClass]="{
    loading: loading,
    disabled: isDisabled,
    active: isActivated,
    focus: isFocused,
    connect: isConnected,
    transparent: isTransparent,
    registration: registration
  }">
  <div class="d-flex flex-row justify-content-around align-items-center pl-1 pr-1">
    <div>{{ buttonText }}</div>
  </div>
</button>