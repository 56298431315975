<div class="box home">
  <div class="logo">
    <img src="{{assetsUrl}}/assets/images/logo-swipe.svg">
  </div>
  <div class="goal">
    Jeu mobile sur<br />
    les années 90
  </div>
  <div class="menu-buttons">
    <ul>
      <li>
        <wide-btn class="home-btn" [buttonText]="btn1" (buttonClick)="rules()" [isDisabled]="false">
        </wide-btn>
      </li>
      <li>
        <wide-btn class="home-btn" [buttonText]="btn2" (buttonClick)="ranking()" [isDisabled]="false">
        </wide-btn>
      </li>
      <li>
        <wide-btn class="home-btn" [buttonText]="btn3" (buttonClick)="start()" [isDisabled]="false" [isFocused]="true">
        </wide-btn>
      </li>
    </ul>
  </div>
</div>