import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../config/app-config.service';
import { delay, retry } from 'rxjs/operators';
import { Challenge } from 'src/app/models/challenge';
import { Card } from 'src/app/models/card';
import { Theme } from 'src/app/models/theme';
import { Player } from 'src/app/models/player';
import { PlayerService } from '../user/player.service';
import { Bar } from 'src/app/models/bar';
import { Combo } from 'src/app/models/combo';

export class CheckUser {
  exists: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class ServerService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private playerService: PlayerService
  ) {}
  private validZipcodes = ['00001', '00002', '00003', '00004'];
  DEBOUNCE = this.appConfig.DEBOUNCE;

  fakeHttp(value: string) {
    if (this.appConfig.DEBUG) {
      console.log('fakeHttp:' + value);
    }
    return of(this.validZipcodes.includes(value)).pipe(delay(100));
  }
  check(name: string): Observable<CheckUser> {
    const data = JSON.stringify({
      name: name,
      bar_id: this.appConfig.BARID,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return this.http.post<CheckUser>(
      this.appConfig.apiUrl + '/check/' + this.appConfig.lang,
      data,
      config
    );
  }
  registerPlayer(name: string): Observable<Player> {
    const data = JSON.stringify({
      name: name,
      bar_id: this.appConfig.BARID,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return this.http.post<Player>(
      this.appConfig.apiUrl + '/user/' + this.appConfig.lang,
      data,
      config
    );
  }

  updateScore(score: number): Observable<Player> {
    const data = JSON.stringify({
      provider_id: this.playerService.id,
      provider_type: 'login',
      score: score,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return this.http.post<Player>(
      this.appConfig.apiUrl + '/score/' + this.appConfig.lang,
      data,
      config
    );
  }

  getBar(barid: string): Observable<Array<Bar>> {
    const url =
      this.appConfig.apiUrl + '/bars/' + this.appConfig.lang + '/' + barid;
    return this.http.get<Array<Bar>>(url).pipe(retry(1));
  }

  updateBar(barid: string): Observable<Array<Number>> {
    if (this.appConfig.DEBUG) {
      console.log('ServerService:updateBar barid' + barid);
    }
    const url =
      this.appConfig.apiUrl + '/update/' + this.appConfig.lang + '/' + barid;
    return this.http.get<Array<Number>>(url).pipe(retry(1));
  }

  getChallenges(): Observable<Array<Challenge>> {
    const url = this.appConfig.apiUrl + '/challenges/' + this.appConfig.lang;
    return this.http.get<Array<Challenge>>(url).pipe(retry(1));
  }
  getThemes(): Observable<Array<Theme>> {
    const url = this.appConfig.apiUrl + '/themes/' + this.appConfig.lang;
    return this.http.get<Array<Theme>>(url).pipe(retry(1));
  }
  getCards(challengeID: string): Observable<Array<Card>> {
    const url =
      this.appConfig.apiUrl +
      '/cards/' +
      this.appConfig.lang +
      '/' +
      challengeID;
    return this.http.get<Array<Card>>(url).pipe(retry(1));
  }

  getRanking(): Observable<Array<Player>> {
    // const data = JSON.stringify({
    //   bar_id: this.appConfig.BARID,
    // });
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
    return this.http.get<Array<Player>>(
      this.appConfig.apiUrl + '/ranking/' + this.appConfig.lang
    );
  }

  getCombos(): Observable<Array<Combo>> {
    const url = this.appConfig.apiUrl + '/combos/' + this.appConfig.lang;
    return this.http.get<Array<Combo>>(url).pipe(retry(1));
  }
}
