import { APP_BASE_HREF } from '@angular/common';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import {
  BrowserModule,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ConnectComponent } from 'src/app/components/connect/connect.component';
import { RegisterComponent } from 'src/app/components/connect/register/register.component';
import { LoginComponent } from 'src/app/components/connect/login/login.component';
import { AlertComponent } from 'src/app/components/connect/alert/alert.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './services/config/app-config.service';
import { ToastNoAnimationModule } from 'ngx-toastr';
import * as Hammer from 'hammerjs';
import { WideBtnComponent } from './components/buttons/wide-btn/wide-btn.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './sections/home/home.component';
import { NicknameComponent } from './sections/nickname/nickname.component';
import { PrehomeComponent } from './sections/prehome/prehome.component';
import { RulesComponent } from './sections/rules/rules.component';
import { SelectGameComponent } from './sections/select-game/select-game.component';
import { SwipeUiComponent } from './components/swipe-ui/swipe-ui.component';
import { GameComponent } from './sections/game/game.component';
import { EndComponent } from './sections/end/end.component';
import { RewardComponent } from './sections/reward/reward.component';
import { ErrorComponent } from './sections/error/error.component';
import { RankingComponent } from './sections/ranking/ranking.component';
import { RotateDeviceComponent } from './components/rotate-device/rotate-device.component';
import { SplashscreenComponent } from './sections/splashscreen/splashscreen.component';
import { BackgroundComponent } from './components/background/background.component';
import { SaveGameComponent } from './sections/save-game/save-game.component';
import { JwtModule } from '@auth0/angular-jwt';
import { MatInputModule } from '@angular/material/input';
import { createCustomElement } from '@angular/elements';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

// making hammer config (3)
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

export function tokenGetter(): any {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    WideBtnComponent,
    PrehomeComponent,
    HomeComponent,
    RulesComponent,
    NicknameComponent,
    SelectGameComponent,
    GameComponent,
    SwipeUiComponent,
    EndComponent,
    RewardComponent,
    ErrorComponent,
    RankingComponent,
    RotateDeviceComponent,
    SplashscreenComponent,
    BackgroundComponent,
    ConnectComponent,
    RegisterComponent,
    LoginComponent,
    AlertComponent,
    SaveGameComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatInputModule,
    AppRoutingModule,
    ToastNoAnimationModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot('G-QKM11JRB31'),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        headerName: 'Authorization',
        throwNoTokenError: false,
        skipWhenExpired: true,
        whitelistedDomains: ['api-swipe90.popcorn-esd.com'],
        blacklistedRoutes: [],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          // Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      },
    },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent],
  // entryComponents: [AppComponent],
})
export class AppModule {
  // constructor(private injector: Injector) {
  //   const myElement = createCustomElement(AppComponent, { injector });
  //   customElements.define('app-element', myElement);
  // }
  // ngDoBootstrap(): void {}
}

// npm run el-build
// https://qrbatch.scanova.io/login
