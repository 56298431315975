import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AppConfigService } from 'src/app/services/config/app-config.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(
    private appConfig: AppConfigService,
    private route: ActivatedRoute,
    private $gaService: GoogleAnalyticsService
  ) {}
  msg = 'Reflash le QR code.';
  assetsUrl = this.appConfig.assetsUrl;
  errorCode: string;
  ngOnInit(): void {
    this.errorCode = this.route.snapshot.paramMap.get('errorCode') || '606';
    switch (this.errorCode) {
      case '606':
        this.msg = 'Erreur serveur.';
        break;
      case '101':
        this.msg = 'Paramètre Bar manquant.';
        break;
      case '303':
        this.msg = 'Bar inexistant.';
        break;
      case '505':
        this.msg = 'Jeu jouable sur telephone.';
        break;
      default:
        this.msg = 'Erreur serveur.';
        break;
    }
    this.$gaService.pageView('/error', 'Error Page');
  }
}
