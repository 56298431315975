import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { AuthenticationService } from 'src/app/services/http/authentication.service';
import {
  CursorService,
  CursorEvent,
} from 'src/app/services/view/cursor.service';
import * as $ from 'jquery';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  placeHolderEmail = 'E-mail';
  placeHolderPassowrd = 'Mot de passe';
  // tslint:disable-next-line: quotemark
  buttonText = 'Me connecter';
  buttonTextOver = 'envoyer ';
  authSubs: Subscription;
  onInputClickBound: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private cursorService: CursorService,
    private appConfig: AppConfigService
  ) {
    // if (this.authService.currentUserValue) {
    //   // this.router.navigate(['/']);
    // }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.email, Validators.minLength(6)],
      ],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.authSubs = this.authService.emitter$.subscribe((event) => {
      if (event.name === this.authService.AUTHFAILED) {
        this.loading = false;
        this.loginForm.controls.email.enable();
        this.loginForm.controls.password.enable();

        this.cursorService.emitEvent(
          new CursorEvent(this.cursorService.NORMAL)
        );
      }
    });

    this.onInputClickBound = this.onInputClick.bind(this);
    $('.form-control').on('click', this.onInputClickBound);
  }

  ngOnDestroy() {
    if (this.appConfig.DEBUG) {
      console.log('LoginComponent:ngOnDestroy');
    }
    this.authSubs.unsubscribe();
    $('.form-control').off('click', this.onInputClickBound);
  }

  onInputClick(event: any) {
    // event.preventDefault();
    event.stopPropagation();
  }
  onRollOverButton(): any {
    if (this.loading) {
      this.cursorService.emitEvent(new CursorEvent(this.cursorService.LOADING));
    } else {
      this.cursorService.emitEvent(
        new CursorEvent(this.cursorService.ROLLOVER)
      );
    }
  }
  onRollOutButton(): any {
    if (this.loading) {
      this.cursorService.emitEvent(new CursorEvent(this.cursorService.LOADING));
    } else {
      this.cursorService.emitEvent(new CursorEvent(this.cursorService.ROLLOUT));
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loginForm.controls.email.disable();
    this.loginForm.controls.password.disable();
    this.loading = true;
    this.cursorService.emitEvent(new CursorEvent(this.cursorService.LOADING));

    // setTimeout(() => {
    this.authService.submitLogin(this.loginForm.value);
    // }, 100000);
    // this.authService.submitLogin(this.loginForm.value);
  }
}
