import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { routeAnimations } from 'src/app/animations/route.animations';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { ServerService } from '../../services/http/server.service';
import { LoginValidator } from 'src/app/services/validators/login-validator';
declare var Inputmask: any;
import { PlayerService } from 'src/app/services/user/player.service';
import { Player } from '../../models/player';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-nickname',
  templateUrl: './nickname.component.html',
  styleUrls: ['./nickname.component.scss'],
  animations: [routeAnimations],
})
export class NicknameComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private serverService: ServerService,
    private appConfig: AppConfigService,
    private playerService: PlayerService,
    private $gaService: GoogleAnalyticsService
  ) {}
  loginForm: FormGroup;

  nickname: string = '';
  btn = 'Valider';
  placeHolder = 'Nom ou Pseudo';
  MINSIZE = 3;
  MAXSIZE = 10;
  submitted = false;
  loading = false;
  assetsUrl = this.appConfig.assetsUrl;

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      nickname: [
        '',
        [
          Validators.required,
          Validators.minLength(this.MINSIZE),
          Validators.maxLength(this.MAXSIZE),
        ],
        LoginValidator.createValidator(this.serverService),
      ],
    });
    this.$gaService.pageView('/nickname', 'Balance ton pseudo !');
  }

  ngAfterViewInit(): void {
    // $('#inputfieldtxt').trigger('focus');
    const im = new Inputmask({
      // tslint:disable-next-line: quotemark
      regex: this.appConfig.INPUT_MASK,
      placeholder: ' ',
    });
    im.mask(document.querySelector('#inputfieldtxt'));
  }

  onChange(event: any): void {}

  // none(): void {
  //   this.router.navigate(['/select-game'], {
  //     skipLocationChange: true,
  //   });
  // }

  back(): void {
    this.router.navigate(['/home'], {
      skipLocationChange: true,
    });
  }

  isValid(username: string): boolean {
    return true;
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid || this.loginForm.pending) {
      this.submitted = false;
      return;
    }
    this.loginForm.controls.nickname.disable();
    this.loading = true;
    this.$gaService.event(
      'Submit pseudo',
      'Interactions',
      this.loginForm.controls.nickname.value
    );

    this.registerPlayer().then((status) => {
      this.loading = false;
      if (status) {
        this.router.navigate(['/select-game'], {
          skipLocationChange: true,
        });
      } else {
        // failed !
        if (this.appConfig.DEBUG) {
          console.error('NicknameComponent:onSubmit registerPlayer failed ');
        }
        this.submitted = false;
      }
    });
  }

  registerPlayer(): Promise<boolean> {
    const promise = new Promise((resolve) => {
      this.serverService
        .registerPlayer(
          (this.loginForm.controls.nickname.value as string).toLowerCase()
        )
        .toPromise()
        .then(
          (data: Player) => {
            this.playerService.setPlayer(data);
            this.$gaService.event(
              'Player registered',
              'Event',
              this.loginForm.controls.nickname.value
            );
            resolve(true);
          },
          (reason: any) => {
            if (this.appConfig.DEBUG) {
              console.error('NicknameComponent:registerPlayer failed' + reason);
            }
            this.$gaService.event(
              'Registration failed',
              'Event',
              this.loginForm.controls.nickname.value
            );
            resolve(false);
          }
        );
    });

    return promise as Promise<boolean>;
  }
}
