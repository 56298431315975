import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { routeAnimations } from 'src/app/animations/route.animations';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import {
  AuthenticationService,
  AuthEvent,
} from 'src/app/services/http/authentication.service';
import { PlayerService } from 'src/app/services/user/player.service';
import { BackgroundService } from 'src/app/services/view/background.service';
import {
  TransitionService,
  TransitionEvent,
} from 'src/app/services/view/transition.service';
import * as $ from 'jquery';

@Component({
  selector: 'save-game',
  templateUrl: './save-game.component.html',
  styleUrls: ['./save-game.component.scss'],
  animations: [routeAnimations],
})
export class SaveGameComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private appConfig: AppConfigService,
    private playerService: PlayerService,
    private $gaService: GoogleAnalyticsService,
    private transitionService: TransitionService,
    private authService: AuthenticationService,
    private backgroundService: BackgroundService
  ) {}
  assetsUrl = this.appConfig.assetsUrl;
  authSubs: Subscription;
  subscription: Subscription;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('SaveGameComponent:ngOnInit ');
    }
    this.authSubs = this.authService.emitter$.subscribe((event) =>
      this.eventDispatch(event)
    );
    this.subscription = this.transitionService.emitter$.subscribe(
      (event: TransitionEvent) => this.eventDispatch(event)
    );
  }

  ngOnDestroy(): void {
    this.authSubs.unsubscribe();
    this.subscription.unsubscribe();
  }

  eventDispatch(event: any): void {
    switch (event.name) {
      case this.authService.AUTHSUCCESS:
        this._onAuthSuccess(event);
        break;
      case this.authService.AUTHFAILED:
        this._onAuthFail();
        break;
      case this.transitionService.DONE:
        if (event.to === 'SaveGame') {
          $('.main').addClass('scrollable');
        }
        break;
      default:
        break;
    }
  }

  _onAuthSuccess(event: AuthEvent): void {
    this.playerService.isConnected = true;
    this.playerService.name = event.user?.name || 'Guest';
    this.playerService.id = event.user?.provider_id || '';
    this.playerService.position = event.user?.position || 0;
    // if (this.appConfig.GA_STATS) {
    this.$gaService.event('Auth Success', 'Events', 'Login Success');
    // }

    this.ngZone.run(() => {
      this.router.navigate(['/ranking'], {
        skipLocationChange: true,
      });
    }); // this.sounsService.stopIntro();
  }
  _onAuthFail(): void {
    // if (this.appConfig.GA_STATS) {
    this.$gaService.event('Auth Failed', 'Events', 'Login failed');
    // }

    this.playerService.isConnected = false;
  }

  close() {
    this.ngZone.run(() => {
      this.router.navigate(['/end'], {
        skipLocationChange: true,
      });
    });
  }
}
