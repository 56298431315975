<div class="box end">
  <div class="loser d-flex flex-column justify-content-center">
    <div class="justify-content-center">
      <img src="{{assetsUrl}}/assets/images/end.gif" class="gif_fin">
    </div>
    <div class="goal">
      Essaie encore !
    </div>
    <div class="action mb-3">
      Regarde ton classement et tente<br />
      de dépasser ton score !
    </div>
    <div class="score">
      <div class="goal red">{{score}}</div>
      <div>
        <img src="{{assetsUrl}}/assets/images/stars.svg" class="Stars">
      </div>
    </div>
  </div>
  <div class="menu-buttons">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <!-- <div class="registration d-flex flex-column justify-content-center align-items-center">
        <div class="goal">INSCRIS-TOI POUR VOIR
          TON CLASSEMENT !</div>
        <div class="desc">Les 10 meilleurs scores gagnent
          une rencontre avec les artistes ou
          une place à l’événement Red Bull SoundClash !</div>
      </div> -->
      <wide-btn class="home-btn mb-3" [buttonText]="btn1" (buttonClick)="ranking()" [isDisabled]="false"
        [registration]="true">
      </wide-btn>
      <wide-btn class="home-btn" [buttonText]="btn2" (buttonClick)="restart()" [isDisabled]="false" [isFocused]="true">
      </wide-btn>
    </div>
  </div>
</div>