import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import * as $ from 'jquery';
import gsap from 'gsap';
import {
  BackgroundEvent,
  BackgroundService,
} from 'src/app/services/view/background.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent implements OnInit, OnDestroy {
  constructor(
    private appConfig: AppConfigService,
    private backgroundService: BackgroundService
  ) {}
  assetsUrl = this.appConfig.assetsUrl;
  subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.backgroundService.emitter$.subscribe(
      (event: BackgroundEvent) => this.eventDispatch(event)
    );

    this.resetBg();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  eventDispatch(event: BackgroundEvent) {
    return;

    switch (event.name) {
      case this.backgroundService.SHOW:
        if (event.id === 1) {
          this.showBg1();
        }
        if (event.id === 2) {
          this.showBg2();
        }
        if (event.id === 3) {
          this.showBg3();
        }
        if (event.id === 4) {
          this.showBg4();
        }
        break;
      case this.backgroundService.HIDE:
        this.hideBg1();
        this.hideBg2();
        this.hideBg3();
        this.hideBg4();
        break;

      default:
        this.showBg1();

        break;
    }
  }

  resetBg() {
    $('.bg1-1').css('top', -250 + 'px');
    $('.bg1-1').css('left', -190 + 'px');
    $('.bg1-2').css('top', -250 + 'px');
    $('.bg1-2').css('right', -190 + 'px');
    $('.bg2-1').css('top', -100 + 'px');
    $('.bg2-1').css('left', -190 + 'px');
    $('.bg2-2').css('top', -100 + 'px');
    $('.bg2-2').css('right', -190 + 'px');
    $('.bg2-3').css('bottom', -100 + 'px');
    $('.bg2-3').css('left', -190 + 'px');
    $('.bg2-4').css('bottom', -100 + 'px');
    $('.bg2-4').css('right', -190 + 'px');
    $('.bg-blue').css(
      'left',
      -window.innerWidth * window.devicePixelRatio + 'px'
    );
    $('.bg-red').css(
      'right',
      -window.innerWidth * window.devicePixelRatio + 'px'
    );
    // $('.bg-red').css('right', '0px');
  }

  showBg1() {
    gsap.to('.bg1-1', { duration: 0.6, top: 0, left: 0, ease: 'expo.out' });
    gsap.to('.bg1-2', { duration: 0.6, top: 0, right: 0, ease: 'expo.out' });
  }
  hideBg1() {
    gsap.to('.bg1-1', {
      duration: 0.6,
      top: -250,
      left: -190,
      ease: 'expo.in',
    });
    gsap.to('.bg1-2', {
      duration: 0.6,
      top: -250,
      right: -190,
      ease: 'expo.in',
    });
  }

  showBg2() {
    gsap.to('.bg2-1', {
      delay: 0.6,
      duration: 0.6,
      top: 0,
      left: 0,
      ease: 'expo.out',
    });
    gsap.to('.bg2-2', {
      delay: 0.6,
      duration: 0.6,
      top: 0,
      right: 0,
      ease: 'expo.out',
    });
    gsap.to('.bg2-3', {
      delay: 0.6,
      duration: 0.6,
      bottom: 0,
      left: 0,
      ease: 'expo.out',
    });
    gsap.to('.bg2-4', {
      delay: 0.6,
      duration: 0.6,
      bottom: 0,
      right: 0,
      ease: 'expo.out',
    });
  }
  hideBg2() {
    gsap.to('.bg2-1', {
      duration: 0.6,
      top: -100,
      left: -190,
      ease: 'expo.in',
    });
    gsap.to('.bg2-2', {
      duration: 0.6,
      top: -100,
      right: -190,
      ease: 'expo.in',
    });
    gsap.to('.bg2-3', {
      duration: 0.6,
      bottom: -100,
      left: -190,
      ease: 'expo.in',
    });
    gsap.to('.bg2-4', {
      duration: 0.6,
      bottom: -100,
      right: -190,
      ease: 'expo.in',
    });
  }

  showBg3() {
    gsap.to('.bg-red', {
      delay: 0.6,
      duration: 0.6,
      opacity: 1,
      right: 0,
      ease: 'expo.inOut',
    });
    gsap.to('.bg-blue', {
      delay: 0.6,
      duration: 0.6,
      opacity: 1,
      left: 0,
      ease: 'expo.inOut',
    });
  }

  hideBg3() {
    gsap.to('.bg-red', {
      duration: 0.3,
      opacity: 1,
      right: -window.innerWidth * window.devicePixelRatio,
      ease: 'expo.in',
    });
    gsap.to('.bg-blue', {
      duration: 0.3,
      opacity: 1,
      left: -window.innerWidth * window.devicePixelRatio,
      ease: 'expo.in',
    });
  }

  showBg4() {
    gsap.to('.bg2-1', {
      delay: 0.6,
      duration: 0.6,
      top: 0,
      left: 0,
      ease: 'expo.out',
    });
    gsap.to('.bg2-2', {
      delay: 0.6,
      duration: 0.6,
      top: 0,
      right: 0,
      ease: 'expo.out',
    });
  }
  hideBg4() {
    gsap.to('.bg2-1', {
      duration: 0.6,
      top: -100,
      left: -190,
      ease: 'expo.in',
    });
    gsap.to('.bg2-2', {
      duration: 0.6,
      top: -100,
      right: -190,
      ease: 'expo.in',
    });
  }
}
