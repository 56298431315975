import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { Player } from 'src/app/models/player';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import { ServerService } from 'src/app/services/http/server.service';
import { BarService } from 'src/app/services/user/bar.service';
import { PlayerService } from 'src/app/services/user/player.service';
import { UtilsService } from 'src/app/services/utilities/utils.service';
import {
  TransitionEvent,
  TransitionService,
} from 'src/app/services/view/transition.service';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit {
  constructor(
    private transitionService: TransitionService,
    private appConfig: AppConfigService,
    private router: Router,
    private ngZone: NgZone,

    private serverService: ServerService,
    private playerService: PlayerService,
    private barService: BarService,
    private $gaService: GoogleAnalyticsService,

    private utils: UtilsService
  ) {}

  assetsUrl = this.appConfig.assetsUrl;
  subscription: Subscription;
  target = '';
  ranking: Array<Player>;
  currentPlayer: Player;
  showCurrentPlayer = false;
  barName = '';

  ngOnInit(): void {
    this.subscription = this.transitionService.emitter$.subscribe(
      (event: TransitionEvent) => this.eventDispatch(event)
    );
    this.$gaService.pageView('/ranking', 'Classement');

    if (this.playerService.isConnected) {
      this.showCurrentPlayer = true;
      this.currentPlayer = new Player();
      this.currentPlayer.name = this.playerService.name;
      this.currentPlayer.score = this.playerService.score;
      this.currentPlayer.position = this.playerService.position;
      this.currentPlayer.scoreStr = this.utils.pad(this.currentPlayer.score, 4);
    }

    this.barName = this.barService.name;

    this.serverService.getRanking().subscribe((data: Array<Player>) => {
      this.ranking = data;
      this.ranking.forEach((player: Player) => {
        player.scoreStr = this.utils.pad(player.score, 4);
      });
      if (this.ranking.length < this.appConfig.TOP_LIMIT) {
        const diff = this.appConfig.TOP_LIMIT - this.ranking.length;
        for (let i = 0; i < diff; i++) {
          const zombie = new Player();
          zombie.name = 'AAA';
          zombie.score = 0;
          zombie.scoreStr = this.utils.pad(zombie.score, 4);
          this.ranking.push(zombie);
        }
      }
    });
  }
  eventDispatch(event: TransitionEvent): void {
    switch (event.name) {
      case this.transitionService.DONE:
        this.target = event.from.toLowerCase();
        break;

      default:
        break;
    }
  }
  none(): void {}
  close(): void {
    this.ngZone.run(() => {
      if (this.playerService.isConnected) {
        this.router.navigate(['/end'], {
          skipLocationChange: true,
        });
      } else {
        this.router.navigate(['/home'], {
          skipLocationChange: true,
        });
      }
    });
  }
}
