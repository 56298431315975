import * as $ from 'jquery';
import * as Hammer from 'hammerjs';

export class Carousel {
  transitionEnd =
    'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend';
  $carousel = $('.rules');
  $container = $('.pane-container', '.rules');
  $panes = $('.pane', '.rules');
  $bullets = $('.bullet', '.bullets');
  paneWidth = 0;
  paneCount = this.$panes.length;
  panBoundary = 0.1; // if the pane is paned .25, switch to the next pane.

  currentPane = 0;
  hammer: any;

  constructor() {
    this.hammer = new Hammer(this.$carousel[0]).on(
      // 'panleft panright panend pancancel',
      'swipeleft swiperight',
      // 'swipeleft swiperight panleft panright panend pancancel',
      this.handleHammer.bind(this)
    );
  }

  setPaneSize(): void {
    this.paneWidth = Number(this.$carousel.width());
    this.$panes.each((i, ele) => {
      $(ele).width(this.paneWidth);
    });
    this.$container.width(this.paneWidth * this.paneCount);
  }
  init(): void {
    this.setPaneSize();
    $(window).on('load resize orientationchange', () => {
      this.setPaneSize();
      this.showPane(this.currentPane);
    });
  }

  showPane(index: any): void {
    this.currentPane = Math.max(0, Math.min(index, this.paneCount - 1));
    // console.log(' index : ' + index);

    // console.log(' currentPane: ' + this.currentPane);
    this.setContainerOffsetX(-this.currentPane * this.paneWidth, true);
    this.$bullets.each((i, ele) => {
      $(ele).removeClass('on');

      if (i === index) {
        $(ele).addClass('on');
      }
    });
  }

  setContainerOffsetX(offsetX: number, doTransition: boolean = true) {
    if (doTransition) {
      this.$container.addClass('transition').one(this.transitionEnd, () => {
        this.$container.removeClass('transition');
      });
    }
    this.$container.css({
      left: offsetX,
    });
  }

  next(): void {
    this.showPane(++this.currentPane < 3 ? this.currentPane : 3);
  }

  prev(): void {
    this.showPane(--this.currentPane > 0 ? this.currentPane : 0);
  }

  handleHammer(e: any) {
    switch (e.type) {
      case 'swipeleft':
      case 'swiperight':
        this.handleSwipe(e);
        break;
      case 'panleft':
      case 'panright':
      case 'panend':
      case 'pancancel':
        this.handlePan(e);
        break;
    }
  }

  handleSwipe(e: any) {
    switch (e.direction) {
      case Hammer.DIRECTION_LEFT:
        this.next();
        break;
      case Hammer.DIRECTION_RIGHT:
        this.prev();
        break;
    }
    this.hammer.stop(true);
  }

  outOfBound() {
    let left = this.$container.position().left;
    return (
      (this.currentPane == 0 && left >= 0) ||
      (this.currentPane == this.paneCount - 1 &&
        left <= -this.paneWidth * (this.paneCount - 1))
    );
  }
  handlePan(e: any) {
    switch (e.type) {
      case 'panleft':
      case 'panright':
        // Slow down at the first and last pane.
        if (this.outOfBound()) {
          e.deltaX *= 0.2;
        }
        this.setContainerOffsetX(-this.currentPane * this.paneWidth + e.deltaX);
        console.log(-this.currentPane * this.paneWidth + e.deltaX);
        break;
      case 'panend':
      case 'pancancel':
        if (Math.abs(e.deltaX) > this.paneWidth * this.panBoundary) {
          if (e.deltaX > 0) {
            this.prev();
          } else {
            this.next();
          }
        } else {
          this.showPane(this.currentPane);
        }
        break;
    }
  }
}
