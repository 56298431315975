import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/config/app-config.service';
import {
  TransitionService,
  TransitionEvent,
} from 'src/app/services/view/transition.service';
import gsap from 'gsap';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';

@Component({
  selector: 'splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
})
export class SplashscreenComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private appConfig: AppConfigService,
    private transitionService: TransitionService
  ) {}

  assetsUrl = this.appConfig.assetsUrl;
  subscription: Subscription;
  blink: any;

  ngOnInit(): void {
    if (this.appConfig.DEBUG) {
      console.log('SplashscreenComponent:ngOnInit');
    }

    this.subscription = this.transitionService.emitter$.subscribe(
      (event: TransitionEvent) => this.eventDispatch(event)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  eventDispatch(event: TransitionEvent): void {
    switch (event.name) {
      case this.transitionService.START:
        if (event.to === 'SplashScreen') {
          gsap.from('.logo', {
            duration: 0.8,
            opacity: 0,
            scale: 2,
            delay: 0.6,
            translateY: 50,
            ease: 'quint.out',
          });
          gsap.from('.vs1', {
            duration: 0.6,
            delay: 1,
            translateX: (-1 * window.innerWidth) / 2 - 50,
            ease: 'expo.out',
          });
          gsap.from('.leto', {
            duration: 0.6,
            delay: 1,
            translateX: (-1 * window.innerWidth) / 2 - 50,
            ease: 'expo.out',
          });
          gsap.from('.guy', {
            duration: 0.6,
            delay: 1,
            translateX: window.innerWidth / 2 + 50,
            ease: 'expo.out',
          });
          gsap.from('.vs2', {
            duration: 0.6,
            delay: 1,
            translateX: window.innerWidth / 2 + 50,
            ease: 'expo.out',
          });
          gsap.from('.vs-icon', {
            duration: 0.6,
            delay: 1.2,
            opacity: 0,
            translateY: 50,
            ease: 'expo.out',
          });
        }
        break;
      case this.transitionService.DONE:
        if (event.to === 'SplashScreen') {
          this.blink = gsap.to('.touch2start', {
            duration: 1,
            opacity: 1,
            repeat: -1,
            yoyo: true,
          });
        }
        $('.splashscreen').on('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          $('.splashscreen').off('click', '**');
          setTimeout(() => {
            $('.main').addClass('background');
            this.ngZone.run(() => {
              this.router.navigate(['/home'], {
                skipLocationChange: true,
              });
            });
          }, 100);
        });
        break;

      default:
        break;
    }
  }
}
